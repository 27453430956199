export const VALIDGUESSES = [
'Aghel',
'Asian',
'Bibel',
'China',
'Duwes',
'Ebrel',
'Frenk',
'Frynk',
'Gonis',
'Grows',
'Hedra',
'Islam',
'Itali',
'Jamys',
'Jovyn',
'Kesva',
'Krist',
'Kroat',
'Maria',
'North',
'Parti',
'Russi',
'Skott',
'Spayn',
'Synta',
'Tamar',
'Tavas',
'Taves',
'Trest',
'Vanow',
'abmow',
'addya',
'afina',
'aghel',
'ahwer',
'akont',
'akord',
'alarm',
'alena',
'alibi',
'alsen',
'alten',
'alyon',
'amari',
'amber',
'ambos',
'ambri',
'ammow',
'amont',
'amser',
'aneth',
'anfel',
'anfur',
'anion',
'anjei',
'anken',
'ankor',
'anown',
'ansur',
'anvas',
'anwew',
'apysi',
'araya',
'ardak',
'argel',
'argol',
'argos',
'argya',
'arvor',
'arvow',
'askel',
'asper',
'aspia',
'assay',
'astel',
'aswon',
'attal',
'aviow',
'avowa',
'aweni',
'awott',
'ayrek',
'aysel',
'baban',
'badna',
'bagas',
'bagha',
'banna',
'banow',
'bardh',
'barna',
'barth',
'basar',
'bason',
'batti',
'bedha',
'benow',
'benyn',
'berdh',
'berth',
'besow',
'besya',
'betys',
'bewek',
'bewhe',
'bewin',
'bibyn',
'bleus',
'blobm',
'bobba',
'bocka',
'bojet',
'bolgh',
'bolla',
'bones',
'bordh',
'borth',
'bosti',
'botas',
'boton',
'bowin',
'bowji',
'brabm',
'bramm',
'brath',
'bregh',
'brehi',
'brest',
'breus',
'brewi',
'breyn',
'briel',
'brili',
'brini',
'broas',
'brodn',
'brogh',
'bronn',
'brons',
'browi',
'brygh',
'buber',
'bubyn',
'budji',
'burow',
'byrla',
'bysow',
'bysya',
'bywhe',
'chanj',
'chiow',
'chons',
'civil',
'dader',
'dalla',
'dalva',
'danon',
'daras',
'dates',
'davas',
'debri',
'dehen',
'delen',
'delit',
'demma',
'densa',
'denti',
'derow',
'desev',
'deski',
'desta',
'dewer',
'dewyn',
'dhadn',
'dhall',
'dhana',
'dhann',
'dhebm',
'dhorn',
'dhort',
'dhowr',
'dhymm',
'diber',
'didal',
'didon',
'dieth',
'difun',
'diguv',
'diown',
'diras',
'disel',
'dison',
'disto',
'divyn',
'diwen',
'diwla',
'dobyl',
'dogen',
'dolli',
'dotya',
'drama',
'dreyn',
'dreys',
'droos',
'drubm',
'drudh',
'drumm',
'dukes',
'dukys',
'durya',
'dybri',
'dyjyn',
'dyllo',
'dyowl',
'dyski',
'dywar',
'ebeli',
'ebron',
'edhen',
'edhom',
'efarn',
'egeri',
'eghen',
'eghes',
'egina',
'eglos',
'ellas',
'emayl',
'eneth',
'enora',
'entra',
'erber',
'erbys',
'erewi',
'erghi',
'error',
'ervys',
'eryow',
'esedh',
'eseli',
'eskis',
'estem',
'estyl',
'ethol',
'ethow',
'ethys',
'euver',
'ewlow',
'ewnhe',
'ewoni',
'ewwar',
'eyles',
'fagel',
'famya',
'fanci',
'farow',
'fedna',
'fekyl',
'fella',
'fergh',
'ferla',
'feski',
'fevyr',
'finek',
'finel',
'flamm',
'flent',
'flock',
'flogh',
'flosh',
'flows',
'fogow',
'folja',
'folji',
'fonya',
'fordh',
'forgh',
'fosow',
'frank',
'friys',
'froas',
'frodn',
'fronn',
'frosa',
'fugya',
'fujik',
'fusen',
'fusta',
'fysek',
'fyski',
'gajys',
'gales',
'galow',
'ganow',
'garan',
'garma',
'garth',
'gasel',
'gavel',
'gaver',
'gedna',
'genam',
'genev',
'genna',
'genys',
'gerva',
'gerys',
'gesti',
'getto',
'gever',
'gewow',
'gidya',
'gidys',
'gitar',
'gladn',
'glann',
'glapp',
'gledh',
'glena',
'gloos',
'glusa',
'gluth',
'glydn',
'glynn',
'gober',
'godha',
'godna',
'godom',
'godor',
'godra',
'gofen',
'gogow',
'gojek',
'goles',
'golgh',
'golok',
'golon',
'golow',
'golya',
'gonis',
'gonna',
'goodh',
'gorge',
'gorgi',
'gorow',
'gorra',
'gosek',
'govel',
'govyn',
'gowek',
'greow',
'greun',
'greys',
'grodn',
'grond',
'gronn',
'gront',
'grows',
'guwow',
'gwadn',
'gwall',
'gwann',
'gwara',
'gwari',
'gwask',
'gwaya',
'gwayv',
'gwedh',
'gwedn',
'gweli',
'gwenn',
'gwern',
'gweus',
'gwias',
'gwiow',
'gwisi',
'gwisk',
'gwith',
'gwius',
'gwlan',
'gwreg',
'gwres',
'gwrys',
'gwydh',
'gwydn',
'gwyll',
'gwynn',
'gwyns',
'hager',
'hanas',
'hanow',
'hardh',
'hatya',
'haval',
'havek',
'havos',
'havow',
'hayow',
'hebma',
'hedna',
'hegol',
'helgh',
'helgi',
'hemma',
'henji',
'henna',
'hensa',
'hensi',
'hesya',
'heudh',
'higen',
'hinek',
'hirhe',
'hirra',
'hobma',
'hodna',
'hogen',
'hokys',
'holan',
'holon',
'holya',
'honan',
'honen',
'hordh',
'horji',
'horys',
'hudel',
'huder',
'hwadn',
'hwans',
'hwegh',
'hweja',
'hwesa',
'hweth',
'hwias',
'hwyja',
'hwypp',
'hwyth',
'hygow',
'hyhyg',
'hymna',
'hynsa',
'imach',
'ismek',
'ithik',
'jevan',
'jolif',
'jorna',
'jouta',
'junya',
'kabma',
'kador',
'kadys',
'kales',
'kalgh',
'kamil',
'kamma',
'kanel',
'kanon',
'kanow',
'kapel',
'karer',
'karow',
'karry',
'karth',
'kasek',
'kasor',
'kavas',
'kavos',
'kawas',
'kayak',
'kayow',
'kedna',
'kegin',
'kegis',
'keher',
'kehes',
'keles',
'kelli',
'kelmi',
'kenes',
'kenna',
'kensa',
'kepar',
'kerdh',
'keres',
'kergh',
'kerna',
'kerow',
'kerri',
'keser',
'keski',
'kesow',
'kesva',
'keudh',
'kever',
'kevos',
'kevri',
'kewar',
'kewer',
'kewri',
'kibya',
'kilgi',
'kinda',
'kiogh',
'kisya',
'klapp',
'kledh',
'klegh',
'kleja',
'klesa',
'klock',
'klogh',
'kloos',
'klott',
'klout',
'kneus',
'knowa',
'kocha',
'kodha',
'kodna',
'kofen',
'kofer',
'koffi',
'koges',
'kogow',
'kokow',
'kolom',
'kolon',
'kolva',
'komma',
'konna',
'konys',
'kooth',
'kopel',
'korev',
'kosek',
'kosel',
'kosow',
'kothe',
'koton',
'kotow',
'kotys',
'kovva',
'kowas',
'kowla',
'kradn',
'krapp',
'krasa',
'kreia',
'kreji',
'krenn',
'kreun',
'kriow',
'krobm',
'kromm',
'kronk',
'krowd',
'krows',
'kruel',
'krysi',
'kudyn',
'kuner',
'kunys',
'kurri',
'kurun',
'kweth',
'kyfya',
'kylgh',
'kymyk',
'kynsa',
'kyrri',
'label',
'labma',
'labol',
'ladra',
'lafyl',
'lagas',
'lagen',
'lagha',
'lagya',
'lamma',
'lanow',
'lasek',
'lasow',
'lavur',
'layys',
'lebma',
'ledan',
'ledni',
'ledri',
'ledya',
'lemen',
'lenel',
'lenes',
'lenji',
'lenna',
'lergh',
'leska',
'leski',
'lesow',
'lesta',
'letus',
'leven',
'lever',
'levna',
'levow',
'lewya',
'linen',
'lions',
'lisiw',
'lojow',
'lojyk',
'lonka',
'looth',
'losow',
'louba',
'lovan',
'lowen',
'lulyn',
'lutys',
'lymna',
'lysow',
'lyven',
'lyver',
'maban',
'magli',
'magor',
'manek',
'maner',
'manga',
'manyn',
'maras',
'margh',
'marow',
'marth',
'marya',
'mater',
'mayni',
'mebyn',
'meder',
'megys',
'melen',
'melin',
'melon',
'melyn',
'menta',
'menya',
'merci',
'meres',
'mergh',
'mesen',
'meska',
'mesya',
'metol',
'metya',
'mewgh',
'mimya',
'minor',
'minow',
'miraj',
'mirow',
'molas',
'molgh',
'molys',
'mones',
'mordu',
'morlu',
'mosek',
'movya',
'moyha',
'mules',
'musik',
'musur',
'mygla',
'mygyl',
'myjer',
'myrgh',
'myska',
'nabma',
'nader',
'naker',
'namma',
'namna',
'namoy',
'nasya',
'natur',
'nebes',
'neghi',
'negys',
'nerth',
'neshe',
'nessa',
'nevek',
'nevow',
'nevra',
'neyja',
'nilon',
'nivel',
'niver',
'nobyl',
'north',
'nosow',
'nosya',
'nosyn',
'noten',
'noter',
'noyth',
'oberi',
'odour',
'offis',
'omach',
'ombla',
'omdhi',
'omsav',
'omwen',
'opera',
'ostel',
'ostys',
'ottra',
'oulya',
'oulys',
'ourys',
'owrek',
'owrgi',
'pabel',
'pabow',
'padyn',
'pagan',
'pajar',
'pajys',
'palas',
'palji',
'palsi',
'palva',
'panch',
'panda',
'pandr',
'paper',
'parde',
'paros',
'parys',
'payna',
'paynt',
'payon',
'peber',
'pedri',
'pejya',
'pekar',
'pella',
'pelva',
'peren',
'perna',
'perth',
'peryl',
'pesen',
'pesya',
'petal',
'peuns',
'peuri',
'pewas',
'piano',
'piben',
'pibow',
'pilen',
'pilya',
'plans',
'playn',
'plisk',
'plobm',
'plott',
'ploum',
'pobas',
'pobel',
'poder',
'podik',
'podyn',
'poken',
'polat',
'polja',
'polsa',
'ponow',
'ponya',
'popti',
'porth',
'posek',
'potya',
'powas',
'powes',
'poynt',
'praga',
'pratt',
'predn',
'prena',
'prenn',
'prest',
'previ',
'pries',
'priow',
'prism',
'prout',
'prysk',
'puber',
'purhe',
'pydna',
'pylla',
'pynag',
'pynna',
'racka',
'radar',
'radna',
'rager',
'ramer',
'ranna',
'rasek',
'rasow',
'reden',
'reken',
'renka',
'resek',
'reson',
'resow',
'reudh',
'revow',
'rewer',
'rewys',
'reydh',
'reyth',
'rimya',
'rivow',
'riyas',
'riyji',
'riysi',
'rogha',
'rohow',
'rolya',
'rosva',
'royow',
'rumen',
'ryver',
'saben',
'saven',
'savla',
'savow',
'sawgh',
'sawor',
'sawya',
'sebon',
'sedha',
'seksi',
'selva',
'senji',
'serra',
'seson',
'sesya',
'sevel',
'sewya',
'shara',
'showr',
'sinya',
'sirys',
'skala',
'skans',
'skath',
'skeri',
'skeuj',
'skeul',
'skiow',
'skith',
'skobm',
'skolk',
'skoll',
'skomm',
'skrif',
'skrij',
'skwir',
'skyll',
'slynk',
'smodh',
'smurf',
'sodas',
'sodha',
'sojet',
'sokor',
'sonek',
'sotel',
'sowen',
'spala',
'spern',
'spisa',
'spong',
'sport',
'sprus',
'staga',
'stank',
'stayr',
'stifa',
'stock',
'stret',
'strif',
'stubm',
'sugal',
'sugen',
'syger',
'syght',
'sygow',
'sylli',
'synsi',
'tabel',
'tagel',
'tagow',
'taksi',
'talek',
'tanlu',
'taran',
'tardh',
'tarow',
'tarya',
'tasow',
'tatou',
'tavol',
'tedna',
'tegen',
'tekel',
'telyn',
'tenna',
'terri',
'tesek',
'tesel',
'tesen',
'tethi',
'tetti',
'tevri',
'tewes',
'tewhe',
'tewyn',
'teylu',
'tiara',
'tiger',
'tigri',
'tirow',
'tiryn',
'tiyas',
'tiyji',
'tiysi',
'tnowi',
'tobma',
'tohow',
'tokyn',
'tolla',
'tomma',
'tonek',
'tonya',
'tooth',
'torek',
'torth',
'torva',
'tosow',
'towla',
'traow',
'tredh',
'tredn',
'tregh',
'trenk',
'trest',
'treus',
'trial',
'triga',
'trobm',
'tromm',
'troos',
'truan',
'truth',
'trygh',
'tryja',
'tybel',
'tykki',
'tyska',
'udnek',
'udnik',
'udnyn',
'ugens',
'ughel',
'undon',
'unita',
'unnek',
'unnik',
'unwos',
'uskis',
'usyow',
'vajya',
'valis',
'vaner',
'varow',
'varya',
'venym',
'vewin',
'video',
'vodka',
'vorow',
'vovya',
'vregh',
'vrith',
'vroas',
'vusur',
'wajys',
'walgh',
'weles',
'werin',
'wober',
'woles',
'worth',
'yalgh',
'ydhyn',
'yetow',
'ygeri',
'yniow',
'ynken',
'ynter',
'ynyal',
'yonka',
'yorgh',
'yskyn',
'ystyn',
'zebra',
]
